import React from 'react';
import { Desktop } from 'react95';

const Title = ({
  children,
  subtitle
}) => {
  return (
    <div style={{ margin: '0 auto' }}>
      <Desktop backgroundStyles={{ background: 'var(--bg-light-color)' }}>
        <h1>
          {subtitle}
          <br />
          {children}
        </h1>
      </Desktop>
    </div>
  )
}

export default Title
