import React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import format from 'date-fns/format'

import Layout from "../components/layout";
import ShareButtons from "../components/share-buttons";
import Title from "../components/title";
import { Anchor, Divider, Window, WindowHeader } from "react95";
import { centeredWindowListItem, iconStyle } from "../styles";

const getPublicationDateString = (game) => {
  return format(
    new Date(game.published_at),
    'M-d-yyyy'
  )
}

const awards = [{
  gameTitle: 'Dissimilate',
  linkHref: 'https://itch.io/jam/lostcartridgejam3/rate/1293813',
  linkText: 'Lost Cartridge Jam 2021 Qualifier, Scored 2/9'
},{
  gameTitle: 'Myelin',
  linkHref: 'https://itch.io/jam/enterthemud/rate/257342',
  linkText: 'Enter the (Multi-User) Dungeon Jam 2018 #1 Gameplay, #2 Overall'
}, {
  gameTitle: 'segfault',
  linkHref: 'https://itch.io/jam/try-jam/rate/664715',
  linkText: 'Try Jam 2020 #1 Overall'
}, {
  gameTitle: 'Replicant',
  linkHref: 'https://itch.io/jam/enterthemud3/rate/674819',
  linkText: 'Enter the (Multi-User) Dungeon Jam 2020 #1 Overall'
}]

const getEmoji = (game) => {
  switch (true) {
    case game.title.includes('Dissimilate'):
      return 'shapes';
    case game.title.includes('DEAD'):
      return 'skull';
    case game.title.includes('Brain'):
      return 'brain';
    case game.title.includes('segfault'):
      return 'laptop';
    case game.title.includes('Replicant'):
      return 'robot';
    case game.title.includes('Myelin'):
      return 'spire';
    case game.title.includes('Invade'):
      return 'alien';
    case game.title.includes('ADDICT'):
      return 'cig';
    default:
      return 'shroom';
  }
}

const GameIndexPage = ({
  data: {
    allItchioGame: {
      edges,
    }
  }
}) => {
  if (!edges || !edges.length) {
    return (
      <Layout
        description="A catalog of games, experiments, and tools."
        subtitle={'Catalog'}
      >
        <Title subtitle="Catalog">
          <span className="heading-accent">Games &<br />Shames</span>
          <span>An error has occurred!</span>
        </Title>
      </Layout>
    );
  }

  const ninetyDaysAgo = Date.now() - (90 * (24 * 60 * 60 * 1000));
  const games = edges.map(edge => edge.node);
  const releasedGames = games
    .filter(game => game.published_at)
    .map(game => {
      let points = game.views_count;
      const publishDate = Date.parse(game.published_at);
      const isNew = publishDate >= ninetyDaysAgo;
      if (isNew) {
        points += 2000;
      }
      return {
        ...game,
        points,
        publishDate,
        isNew,
      }
    })
    .sort((a, b) => {
      return a.points <= b.points ? 1 : -1;
    });
  const unreleasedGames = games.filter(game => !game.published_at);
  return (
    <Layout
      description="A catalog of games, experiments, and tools."
      subtitle={'Catalog'}
    >
      <Window className="games-category-container" style={centeredWindowListItem}>
        <WindowHeader as="h2" className="window-header-icon">
          <StaticImage
            alt=""
            aria-hidden="true"
            src="../images/documents.png"
            style={iconStyle}
          />
          Unreleased Games
        </WindowHeader>
        <ul className="games-list">
          {unreleasedGames.map((game, i) => (
            <li className={getEmoji(game)} key={game.id}>
              <a className="link" href={game.url} target="_blank" rel="noreferrer noopener">
                <Anchor as="h3">{game.title}</Anchor>
              </a>
              <p>{game.short_text}</p>
              {i < (unreleasedGames.length - 1) ? <Divider></Divider> : null}
            </li>
          ))}
        </ul>
      </Window>
      <Window className="games-category-container" style={centeredWindowListItem}>
      <WindowHeader as="h2" className="window-header-icon">
        <StaticImage
          alt=""
          aria-hidden="true"
          src="../images/disc.png"
          style={iconStyle}
        />
          Released Games
      </WindowHeader>
      <ul className="games-list">
        {releasedGames.map((game, i) => {
          const award = awards.find(awd => game.title.includes(awd.gameTitle));
          return (
          <li className={getEmoji(game)} key={game.id}>
            <a className="link" href={game.url} target="_blank" rel="noreferrer noopener">
              <Anchor as="h3">{game.title}</Anchor>
            </a>
            <div style={{ marginBottom: '2em' }}>
              <span className="blog-post-date">Released {getPublicationDateString(game)}</span>
              <span className="game-price">{game.min_price ? `$${game.min_price}` : 'Free'}</span>
              {game.isNew && <span className="game-price">New!</span>}
            </div>
            <p>{game.short_text}</p>
            {award && (
              <span className="award">🏆{' '}
                <Anchor as="a"
                  className="link"
                  href={award.linkHref}
                  target="_blank" rel="noreferrer noopener"
                >
                  {award.linkText}
                </Anchor>
              </span>
            )}
            {i < (releasedGames.length - 1) ? <Divider style={{ margin: '2rem auto 2rem auto', width: '95%' }}></Divider> : null}
          </li>
        )})}
      </ul>
    </Window>
    <ShareButtons
      title={`Check out these games`}
      tags={['gamedev', 'indiegames']}
    />
  </Layout>);
}


export default GameIndexPage;
export const pageQuery = graphql`
  query GameQuery {
    allItchioGame(limit: 10) {
      edges {
        node {
          id,
          url,
          title,
          short_text,
          published,
          published_at,
          views_count,
          min_price,
        }
      }
    }
  }
`;
